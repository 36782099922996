.link {
  color: var(--primary-700, #1E5BCB);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.link_gray {
  color: var(--gray-500, #667085);
  font-weight: 400;
}

.link_gray-underline {
  color: var(--gray-400, #98A2B3);
  font-weight: 500;
}

.link_gray-underline:hover {
  text-decoration: underline;
  color: var(--primary-700, #1E5BCB);
}