.tabs {
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  padding: 8px 16px 0;
}

.tabs-auth {
  border-radius: 8px;
  background: var(--gray-50, #F9FAFB);
  padding: 4px;
}

.item {
  width: 100%;
  padding-bottom: 16px;
  text-align: center;
  list-style-type: none;
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.item-auth {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  list-style-type: none;
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.item_active-auth {
  background: #FFFFFF;
  color: var(--gray-700, #344054);
  font-weight: 600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
              0px 1px 3px rgba(16, 24, 40, 0.10);
  border-radius: 6px;
  cursor: default;
}


.item_active-auth:hover {
  background: #FFFFFF;
  color: var(--gray-700, #344054);
  font-weight: 600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
              0px 1px 3px rgba(16, 24, 40, 0.10);
  cursor: default;
  border-radius: 6px;
}

.item-auth:hover {
  background: #FFFFFF;
  color: var(--gray-700, #344054);
  font-weight: 600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
              0px 1px 3px rgba(16, 24, 40, 0.10);
  cursor: default;
}

.item:hover {
  color: var(--primary-500, #5993FE);
  border-bottom: 2px solid var(--primary-500, #5993FE);
}

.item_active {
  color: var(--primary-700, #1E5BCB);
  border-bottom: 2px solid var(--primary-700, #1E5BCB);
  cursor: default;
}

.item_active:hover {
  color: var(--primary-700, #1E5BCB);
  border-bottom: 2px solid var(--primary-700, #1E5BCB);
}

.item_disabled {
  cursor: default;
  color: var(--gray-300, #D0D5DD);
  border-bottom: none;
}

.item_disabled:hover {
  cursor: default;
  color: var(--gray-300, #D0D5DD);
  border-bottom: none;
}

.tabs-sm {}

.tabs-sm .item {
  font-size: 14px;
  line-height: 20px;
}