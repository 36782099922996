
.user-agreement_container {
  width: 1041px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 32px 32px;
  flex-direction: column;
  border-right: 1px solid var(--gray-200, #EAECF0);
}

.agreement_section {
  display: flex;
  flex-direction: column;
  color: var(--gray-700, #344054);
  font-family: Inter;
}

.section_header {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding-bottom: 4px;
}

.details_list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  list-style-type: none;
  padding: 0 32px 12px 32px;
  margin: 0;
}

.details-list_item {
  display: flex;
  gap: 12px;
}

.details-list_item_dot {
  list-style-type: disc;
  list-style-position: inside;
}

.agreement_details {
  word-break: break-word;
  hyphens: auto;
  max-width: 90svw;
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.modal_scroll {
  overflow-x: auto;
  max-height: 70svh;
  padding-right: 8px;
  width: 100%;
}

.modal_scroll::-webkit-scrollbar {
  width: 8px;
}

.modal_scroll::-webkit-scrollbar-track {
  border-radius: 2px;
  margin: 4px;
}

.modal_scroll::-webkit-scrollbar-thumb {
  background: var(--gray-200, #EAECF0);
  border-radius: 10px;
}

.modal_scroll::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400, #98A2B3);
}

.link {
  text-decoration: none;
}

@media (min-width: 375px) and (max-width: 1080px) {
  .user-agreement_container {
    width: 100%;
    gap: 24px;
    padding: 20px 16px;
    border-right: none;
  }
  .agreement_details {
    margin-bottom: 4px;
  }

  .details_list {
    gap: 8px;
  }
}