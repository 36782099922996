.header_back {
  height: 0px;
}

.right {
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  gap: 20px;
  min-width: 312px;
  max-width: 312px;
  padding-left: 32px;
  border-left: 1px solid var(--Gray-200, #EAECF0);
  margin-left: -1px;
}

.search {
  margin: 0;
}

.link{
  font-weight: 500;
}

.documents {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

@media screen and (max-width: 1280px) {
  .right {
    display: none;
  }
}