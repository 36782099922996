.loader-container {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  gap: 16px;
  padding-top: 10px;
}

.button-loader {
  padding-top: 0px;
}

.loader-container_lg-xl {
  font-size: 18px;
  line-height: 28px;
}

/* .loader-container_xs {
  font-size: 16px;
  line-height: 20px;
} */

.loader_quart {
  align-self: center;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  border: 3px solid var(--primary-50, #eef4ff);
  border-bottom-color: var(--primary-600-main, #367dff);
}

.loader_line {
  align-self: center;
  border: 5px solid var(--primary-600-main, #367dff);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.size-xs{
  width: 20px;
  height: 20px;
  border-width: 2px;
}

.size-sm{
  width: 32px;
  height: 32px;
  border-width: 3px;
}

.size-md{
  width: 48px;
  height: 48px;
  border-width: 6px;
}

.size-lg{
  width: 56px;
  height: 56px;
  font-size: 18px;
  border-width: 6px;
}

.size-xl{
  width: 64px;
  height: 64px;
  font-size: 18px;
  border-width: 8px;
}

.loader_dots{
  align-self: center;
  border-radius: 100%;
  margin-bottom: 16px;
  animation: rotation 1s steps(16, end) infinite;
}

.size-dots_sm{
  width: 3px;
  height: 3px;
  box-shadow:
  15px 0px hsla(219, 100%, 70%, 0.0625),
  13.86px 5.74px hsla(219, 100%, 61%, 0.125),
  10.61px 10.61px hsla(219, 100%, 61%, 0.1875),
  5.74px 13.86px hsla(219, 100%, 61%, 0.25),
  0px 15px hsla(219, 100%, 61%, 0.3125),
  -5.74px 13.86px hsla(219, 100%, 61%, 0.375),
  -10.61px 10.61px hsla(219, 100%, 61%, 0.4375),
  -13.86px 5.74px hsla(219, 100%, 61%, 0.5),
  -15px 0px hsla(219, 100%, 61%, 0.5625),
  -13.86px -5.74px hsla(219, 100%, 61%, 0.625),
  -10.61px -10.61px hsla(219, 100%, 61%, 0.6875),
  -5.74px -13.86px hsla(219, 100%, 61%, 0.75),
  0px -15px hsla(219, 100%, 61%, 0.8125),
  5.74px -13.86px hsla(219, 100%, 61%, 0.875),
  10.61px -10.61px hsla(219, 100%, 61%, 0.9375),
  13.86px -5.74px hsla(219, 100%, 61%, 1);
}

.size-dots_md{
  width: 6px;
  height: 6px;
  box-shadow:
  21.5px 0px hsla(219, 100%, 70%, 0.0625),
  19.86px 8.23px hsla(219, 100%, 61%, 0.125),
  15.2px 15.2px hsla(219, 100%, 61%, 0.1875),
  8.23px 19.86px hsla(219, 100%, 61%, 0.25),
  0px 21.5px hsla(219, 100%, 61%, 0.3125),
  -8.23px 19.86px hsla(219, 100%, 61%, 0.375),
  -15.2px 15.2px hsla(219, 100%, 61%, 0.4375),
  -19.86px 8.23px hsla(219, 100%, 61%, 0.5),
  -21.5px 0px hsla(219, 100%, 61%, 0.5625),
  -19.86px -8.23px hsla(219, 100%, 61%, 0.625),
  -15.2px -15.2px hsla(219, 100%, 61%, 0.6875),
  -8.23px -19.86px hsla(219, 100%, 61%, 0.75),
  0px -21.5px hsla(219, 100%, 61%, 0.8125),
  8.23px -19.86px hsla(219, 100%, 61%, 0.875),
  15.2px -15.2px hsla(219, 100%, 61%, 0.9375),
  19.86px -8.23px hsla(219, 100%, 61%, 1);
}

.size-dots_lg{
  width: 8px;
  height: 8px;
  box-shadow:
  25px 0px hsla(219, 100%, 70%, 0.0625),
  23.1px 9.57px hsla(219, 100%, 61%, 0.125),
  17.68px 17.68px hsla(219, 100%, 61%, 0.1875),
  9.57px 23.1px hsla(219, 100%, 61%, 0.25),
  0px 25px hsla(219, 100%, 61%, 0.3125),
  -9.57px 23.1px hsla(219, 100%, 61%, 0.375),
  -17.68px 17.68px hsla(219, 100%, 61%, 0.4375),
  -23.1px 9.57px hsla(219, 100%, 61%, 0.5),
  -25px 0px hsla(219, 100%, 61%, 0.5625),
  -23.1px -9.57px hsla(219, 100%, 61%, 0.625),
  -17.68px -17.68px hsla(219, 100%, 61%, 0.6875),
  -9.57px -23.1px hsla(219, 100%, 61%, 0.75),
  0px -25px hsla(219, 100%, 61%, 0.8125),
  9.57px -23.1px hsla(219, 100%, 61%, 0.875),
  17.68px -17.68px hsla(219, 100%, 61%, 0.9375),
  23.1px -9.57px hsla(219, 100%, 61%, 1);
}

.size-dots_xl{
  width: 8px;
  height: 8px;
  box-shadow:
  30px 0px hsla(219, 100%, 70%, 0.0625),
  27.72px 11.48px hsla(219, 100%, 61%, 0.125),
  21.21px 21.21px hsla(219, 100%, 61%, 0.1875),
  11.48px 27.72px hsla(219, 100%, 61%, 0.25),
  0px 30px hsla(219, 100%, 61%, 0.3125),
  -11.48px 27.72px hsla(219, 100%, 61%, 0.375),
  -21.21px 21.21px hsla(219, 100%, 61%, 0.4375),
  -27.72px 11.48px hsla(219, 100%, 61%, 0.5),
  -30px 0px hsla(219, 100%, 61%, 0.5625),
  -27.72px -11.48px hsla(219, 100%, 61%, 0.625),
  -21.21px -21.21px hsla(219, 100%, 61%, 0.6875),
  -11.48px -27.72px hsla(219, 100%, 61%, 0.75),
  0px -30px hsla(219, 100%, 61%, 0.8125),
  11.48px -27.72px hsla(219, 100%, 61%, 0.875),
  21.21px -21.21px hsla(219, 100%, 61%, 0.9375),
  27.72px -11.48px hsla(219, 100%, 61%, 1);
}

.loader_download{
align-self: center;
}